.container {
  margin: 1rem 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  background-color: #FAFAFA;
  padding: 2rem;
  height: auto;
  width: 36rem;
}

/**************************/
/* BELOW 120em             */
/**************************/

@media (max-width: 150em) {
  .logo {
    width: 24rem;
    padding: 1rem;
  }
}

@media (max-width: 90em) {
  .logo {
    width: 24rem;
    padding: 1rem;
  }
}


@media (max-width: 50em) {
  .logo {
    width: 14rem;
    padding: 1rem;
  }
}