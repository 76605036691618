.container {
  margin: 0 0 2rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.logo {
  height: 5rem;
  width: 5rem;
  margin-right: 2rem;
}
