.container {
  margin: 0;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.info {
  text-align: left;
  margin: 4rem 20rem 0 20rem;
}

.infoText {
  font-size: 2.4rem;
  line-height: 4rem;
}
.row {
  margin: 0;
}

@media (max-width: 150em) {
  .info{
    margin: 2rem 4rem 0 4rem;
  }
  .infoText {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}



@media (max-width: 90em) {
  .info{
    margin: 2rem 4rem 0 4rem;
  }
  .infoText {
    font-size: 1.5rem;
    line-height: 2.6rem;
  }
}

@media (max-width: 50em) {
  .info{
    margin: 1rem 1rem 0 1rem;
  }
  .infoText {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

