.App {
  text-align: center;
  background-color: #111111;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #FAFAFA;
}

.App-link {
  color: #777777;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
