.container {
  z-index: 100;
  width: 22rem;
  background-color: #111111;
  margin: 0;
  transform: rotate(-15deg);
  translate: -5rem 7.5rem;
  padding: 0.5rem 1rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: #777777;
}
.containerText {
  line-height: 1rem;
  font-weight: 800;
  font-size: 3rem;
}

@media (max-width: 150em) {
  .container {
    width: 16rem;
    translate: -4rem 5.5rem;
    padding: 0.5rem 1rem;
  }
  .containerText {
    line-height: 0.8rem;
    font-weight: 800;
    font-size: 2rem;
  }
}

@media (max-width: 50em) {
  .container {
    width: 10rem;
    translate: -3rem 4.5rem;
    padding: 0.3rem .5rem;
    border-width: .3rem;
  }
  .containerText {
    line-height: 0.8rem;
    font-weight: 800;
    font-size: 1.5rem;
  }
}
